import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  LoginInfo
 } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  constructor(private http: HttpClient) {}

  post(
    loginInfo: LoginInfo
  ): Observable<string> {
  const url = '/api/Authorization';

  // #region Send Request
  return this.http.post<string>(
    url,
    loginInfo
  );
  // #endregion
}

}
