import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { LoaderInterceptorService, LoaderService } from './cores';
import { LoaderComponent } from './components';

const CORES = [LoaderInterceptorService, LoaderService];
const COMPONENTS = [LoaderComponent];

@NgModule({
  declarations: [COMPONENTS],
  imports: [CommonModule, MaterialModule],
  exports: [MaterialModule, COMPONENTS],
  providers: [CORES]
})
export class ThemeModule {}
