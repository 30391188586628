import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ReportMeta,
  Report
 } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient) {}

  list(
    newFirst?: boolean
  ): Observable<ReportMeta[]> {
  let url = '/api/Report';

  const queryList = [];

  // #region Query Parameter Name: newFirst
  if ( newFirst !== null && newFirst !== undefined ) {
    queryList.push('newFirst=' + encodeURIComponent(newFirst.toString()));
  }
  // #endregion
  
  // Append URL
  if ( queryList.length > 0 ) {
    url += '?' + queryList.join('&');
  }

  // #region Send Request
  return this.http.get<ReportMeta[]>(
    url
    );
    // #endregion
}

  create(
    report: Report
  ): Observable<Report> {
  const url = '/api/Report';

  // #region Send Request
  return this.http.post<Report>(
    url,
    report
  );
  // #endregion
}

  update(
    report: Report
  ): Observable<Report> {
  const url = '/api/Report';

  // #region Send Request
  return this.http.put<Report>(
    url,
    report
  );
  // #endregion
}

  downloadExcel(
    id: string
  ): Observable<string> {
  let url = '/api/Report/{id}/download';

  // #region Path Parameter Name: id
  url = url.replace('{id}', id.toString());
  // #endregion

  // #region Send Request
  return this.http.get<string>(
    url
    );
    // #endregion
}

  batchDownloadExcel(
    id?: string[]
  ): Observable<string> {
  let url = '/api/Report/batch-download';

  const queryList = [];

  // #region Query Parameter Name: id
  if ( id !== null && id !== undefined ) {
    for ( const item of id ) {
      if ( item !== null && item !== undefined ) {
        queryList.push('id=' + encodeURIComponent(item.toString()));
      }
    }
  }
  // #endregion
  
  // Append URL
  if ( queryList.length > 0 ) {
    url += '?' + queryList.join('&');
  }

  // #region Send Request
  return this.http.get<string>(
    url
    );
    // #endregion
}

  get(
    id: string
  ): Observable<Report> {
  let url = '/api/Report/{id}';

  // #region Path Parameter Name: id
  url = url.replace('{id}', id.toString());
  // #endregion

  // #region Send Request
  return this.http.get<Report>(
    url
    );
    // #endregion
}

  delete(
    id: string
  ): Observable<void> {
  let url = '/api/Report/{id}';

  // #region Path Parameter Name: id
  url = url.replace('{id}', id.toString());
  // #endregion

  // #region Send Request
  return this.http.delete<void>(
    url
    );
    // #endregion
}

  export(
    id: string
  ): Observable<void> {
  let url = '/api/Report/{id}/export';

  // #region Path Parameter Name: id
  url = url.replace('{id}', id.toString());
  // #endregion

  // #region Send Request
  return this.http.get<void>(
    url
    );
    // #endregion
}

}
