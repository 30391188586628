import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  AuthorizationService,
  ReportService
} from './services';
import {
  AuthorizationPostResolve,
  ReportListResolve,
  ReportCreateResolve,
  ReportUpdateResolve,
  ReportDownloadExcelResolve,
  ReportBatchDownloadExcelResolve,
  ReportGetResolve,
  ReportDeleteResolve,
  ReportExportResolve
} from './resolves';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: [
    AuthorizationService,
    ReportService,
    AuthorizationPostResolve,
    ReportListResolve,
    ReportCreateResolve,
    ReportUpdateResolve,
    ReportDownloadExcelResolve,
    ReportBatchDownloadExcelResolve,
    ReportGetResolve,
    ReportDeleteResolve,
    ReportExportResolve
  ]
})
export class yuntechModule {
}
