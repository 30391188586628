import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { ReportService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class ReportCreateResolve implements Resolve<any> {

  constructor(private reportService: ReportService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.reportService.create(
      route.data.report || route.params.report || route.queryParams.report
    );
  }
}
